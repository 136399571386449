import React from "react";
import {FastClick} from "searchkit";

export const NonblankMenuItem = (props) => (
    <FastClick handler={props.onClick}>
        <div className={props.bemBlocks.option().state({active:props.active}).mix(props.bemBlocks.container("item"))} data-qa="option" data-key={props.itemKey}>
            <div className={props.bemBlocks.option("text")}>{ props.label ? props.label : <em>(blank)</em>}</div>
            <div className={props.bemBlocks.option("count")}>{props.count}</div>
        </div>
    </FastClick>
);
export const NonblankCheckboxItem = (props) => (
    <FastClick handler={props.onClick}>
        <div className={props.bemBlocks.option().state({active:props.active}).mix(props.bemBlocks.container("item"))} data-qa="option" data-key={props.itemKey}>
            <input type="checkbox" data-qa="checkbox" checked={props.active} readOnly className="sk-item-list-option__checkbox"/>
            <div className={props.bemBlocks.option("text")}>{ props.label ? props.label : <em>(blank)</em>}</div>
            <div className={props.bemBlocks.option("count")}>{props.count}</div>
        </div>
    </FastClick>
);
import React from 'react';
import {TraxNavbar} from "./TraxNavbar";
import {Link, withRouter} from "react-router-dom";
// import Alert from "react-bootstrap/Alert";
import {Row, Image, Jumbotron} from "react-bootstrap";


const ResourceNotFound = () => {
    return (
        <div className="wrapper">
            <div className="container">
            <TraxNavbar/>
            {/*<Alert variant="warning">Note: This database is still in preview mode.  Changes made here WILL NOT PERSIST past the next data pull.  Content & functionality may change without warning.</Alert>*/}
            <Jumbotron>
                <Row>
                    <div className="col-sm-4 col-sm-offset-1">
                        <Image src="tunnel-2325109_1280.png" fluid title="Image of empty train tunnel"/>
                    </div>
                    <div className="com-sm-6">
                        <h2>Oops!</h2>
                        <p>The page you are looking for does not exist.  Please <Link to="/trax">Click Here</Link> to return to search.</p>
                    </div>
                </Row>
            </Jumbotron>
            </div>
        </div>
    );
};

export default withRouter(ResourceNotFound);
const renderJoinedIfArray = function renderJoinedIfArray(value){
    if (value instanceof Array) {
        return value.join(', ');
    } else {
        return value;
    }
};

const truncateString = function(text, length){
    return (text.length > length) ? text.substr(0, length) + '...' : text;
};

module.exports = {
    renderJoinedIfArray: renderJoinedIfArray,
    truncateString: truncateString
};
import React, { Component } from 'react';

import { withAuth } from '@okta/okta-react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import NavDropdown from "react-bootstrap/NavDropdown";
import {LinkContainer} from 'react-router-bootstrap';

class LoginButton extends Component {
    state = {
        authenticated: null,
        user: null,
    };

    componentDidUpdate() {
        this.checkAuthentication();
    }

    componentDidMount() {
        this.checkAuthentication();
    }


    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            const user = await this.props.auth.getUser();
            this.setState({ authenticated, user });
        }
    }

    login = () => this.props.auth.login();
    logout = () => {
        this.props.auth.logout();
    };

    render() {
        const { authenticated, user } = this.state;

        if (authenticated == null) return null;
        if (!authenticated) return <Button variant="primary" onClick={this.login}>Login</Button>;

        const menuPosition = {
            vertical: 'top',
            horizontal: 'right',
        };

        return (
            <NavDropdown title={<FontAwesomeIcon icon={faUser}/>} id="user-menu-dropdown" alignRight>
                <LinkContainer to="/trax/create">
                    <NavDropdown.Item title="Create Record">Create Record</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
            </NavDropdown>
        );
    }
}
export default withAuth(LoginButton);
import React, { Component } from 'react';
const react_select_1 = require('react-select');

function clone(obj: any) {
    if (!obj) {
        return null;
    }
    // return JSON.parse(JSON.stringify(obj));
    return { ...obj };
}
function deepFind(obj, propPath, defaultVal) {
    if (typeof obj === 'undefined' || obj === null) {
        return null;
    }
    let result = clone(obj);
    let arr = typeof propPath === 'string' ? propPath.split('.') : [propPath];
    for (let _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        let propName = arr_1[_i];
        if (!result) {
            return defaultVal;
        }
        if (!result.hasOwnProperty(propName)) {
            return defaultVal;
        }
        result = result[propName];
    }
    return result;
}

export default class MultiSelect extends Component {
    componentWillMount = () => {
        this.triggerOnChange(this.props);
    };

    componentWillReceiveProps = (newProps) => {
        if (this.props.options !== newProps.options) {
            // trigger onChange when "options" changed (after fetching & setting options)
            this.triggerOnChange(newProps);
        }
    };

    triggerOnChange = (newProps) => {
        if (!newProps.autoOnChange || newProps.autoOnChange === false){
            return;
        }
        let fieldName = newProps.name;
        let val = deepFind(newProps.formik.values, fieldName);
        // from the value => look up to find changedItem { label, value } to trigger onChange
        if (newProps.options && Array.isArray(newProps.options)) {
            let changedItem = newProps.options.find(function (opt) { return opt.value === val; });
            if (changedItem && newProps.onChange) {
                newProps.onChange({ item: changedItem, formik: newProps.formik });
            }
        }
    };

    render = () => {
        let _this = this;
        let fieldName = this.props.name;
        let options = this.props.options || [];
        let values = this.props.formik.values;
        let disabled = this.props.disabled;
        // required for testing to identify the field
        // const classFieldName = `__${this.props.name.split('.').join('-')}`
        let placeholder = options && options[0] && options[0].label === 'Loading...' ? 'Loading...' : this.props.placeholder || 'Select';
        if (!options || options.length === 0) {
            placeholder = '';
        }
        let val = deepFind(values, fieldName, []);
        // let selectedOpts = options.find(function (opt) { return opt.value === val; });
        let selectedOpts = options.filter((opt) => val.includes(opt.value) );
        // console.log("selectedOpt2: "+JSON.stringify(selectedOpts));
        // className={`${css.main} ${classFieldName}`}
        return (React.createElement(react_select_1["default"], { className: this.props.className, isMulti: true, isClearable: true, value: selectedOpts, placeholder: placeholder, options: options, disabled: disabled, searchable: options && options.length > 5 ? true : false, onChange: function (items) {
                // items is array of selected options [ { label: "...", value: "..." } ]
                let vals = items.map(function (obj) { return obj.value; });
                _this.props.formik.setFieldValue(fieldName, items ? vals : '');
                _this.props.onChange && _this.props.onChange({ formik: _this.props.formik });
            } }));
    };
};
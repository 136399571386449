import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import DocumentView from "./components/DocumentView";

import {ImplicitCallback, SecureRoute} from '@okta/okta-react';

import { SearchPage } from "./components/searchkit/SearchApp";
import DocumentEditor from "./components/DocumentEditor";
import {TraxNavbar} from "./components/TraxNavbar";
import Alert from "react-bootstrap/Alert";
import ResourceNotFound from "./components/ResourceNotFound";

const DocPage = ({match, location}) => {
    return (
        <DocumentView docId={match.params.id}/>
    );
};
const DocEditPage = ({match, location}) => {
    return (
        <div className="wrapper">
            <div className="container">
                <TraxNavbar/>
                <DocumentEditor docId={match.params.id}/>
            </div>
        </div>
    );
};
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = { doc: ""};
  }

  componentDidMount(): void {
    //this.getDoc();
  }

  render() {
    // const { doc } = this.state;
    return (
        <Router>
          <div className="main-container">
            <Switch>
                <Route exact path='/' component={ SearchPage }/>
                <Route exact path='/trax' component={ SearchPage }/>
                <SecureRoute path='/trax/create' component={ DocEditPage }/>
                <SecureRoute path='/trax/:id/edit' component={ DocEditPage }/>
                <Route path='/trax/:id' component={ DocPage }/>
                <Route path="/implicit/callback" component={ImplicitCallback}/>
                <Route path="/404" component={ResourceNotFound}/>
                <Redirect to="/404"/>
            </Switch>
          </div>
        </Router>
    );
  }
}

export default App;

import React from 'react';
import * as Yup from 'yup';
import {FormContainer, Form, Field, Button} from "ui-form-field";
// import { SingleSelect, MultiSelect } from 'ui-form-field/lib/custom';
import MultiSelect from "./ZMultiSelect";
import {
    trax_genres,
    trax_campaign_options,
    trax_candidate_options_by_campaign,
    trax_music_type_options,
    trax_event_type_options,
    trax_media_options
} from "../utils/trax_metadata";
import {withAuth} from '@okta/okta-react';
import {withRouter} from "react-router-dom";
import Card from "react-bootstrap/Card";
import axios from 'axios';
import { compose } from 'recompose';
import ReactTooltip from "react-tooltip";
import { AddressAutofill, AddressMinimap } from "@mapbox/search-js-react";

const schema = Yup.object().shape({
    song_title: Yup.string().required("Song Title is Required")
});

const empty_doc = {
    song_title: "",
    artist: "",
    genre: [],
    music_type: "",
    event_title: "",
    event_type: "",
    date: "",
    campaign: "2024 Campaign",
    candidate: "",
    candidate_positive: "",
    candidate_negative: "",
    notes: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    // geo_location: {
    //     lat: 0,
    //     lon: 0
    // },
};

const empty_feature = {
    type: "Feature",
    geometry: {
        type: 'Point',
        coordinates: [0,0]
    },
    properties: {
        feature_name: 'Nowhere'
    }
}

const minimap_theme = {
    variables: {
        border: '4px solid #bbb',
        borderRadius: '8px',
        boxShadow: '0 2px 4px #000'
    }
}

const minimap_style = ['mapbox', 'outdoors-v11']

class DocumentEditor extends React.Component {
    state: any = {doc: empty_doc, feature: empty_feature, showMinimap: false, showAddressFormExpanded: false};
    access_token = "pk.eyJ1IjoiemFja3dpdGhhayIsImEiOiJjbGNndGJjM3owYnB0NDFvNmZjN3Jjc3hmIn0.X-x-fKIl-3whzW1ptzoraw"

    componentDidMount(): void {
        if (this.props.docId) {
            const url = "/api/trax/" + this.props.docId;
            fetch(url)
                .then(res => res.json())
                .then(doc => {
                    if (!doc){
                        throw new Error("No doc!");
                    }
                    let initVals = doc._source;
                    if(initVals.address || initVals.city || initVals.state) {
                        this.setState({showAddressFormExpanded: true})
                    }
                    if(initVals.geo_location){
                        console.log("Location found: "+JSON.stringify(initVals.geo_location))
                        let feature = {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [parseFloat(initVals.geo_location.lon), parseFloat(initVals.geo_location.lat)]
                            },
                            properties: {}
                        }
                        console.log("Setting feature: "+JSON.stringify(feature))
                        this.setState({feature: feature, showMinimap: true})
                    }
                    var links = initVals['media_links']
                    if(links && links instanceof Array && links.length >= 1){
                        for (const l of links){
                            let fieldname = 'media_' + l['type'];
                            initVals[fieldname] = l['link'];
                        }
                    }
                    this.setState({doc: initVals})
                    console.log("Updated state: "+JSON.stringify(this.state))
                })
                .catch(err => {console.log("Fetch Error: "+err); this.props.history.push("/404")});
        } else {
            this.setState({doc: empty_doc});
        }
        this.checkAuthentication();
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.docId !== this.props.docId){
            if (this.props.docId) {
                const url = "/api/trax/" + this.props.docId;
                fetch(url)
                    .then(res => res.json())
                    .then(doc => {
                        if (!doc){
                            throw new Error("No doc!");
                        }
                        let initVals = doc._source;
                        this.setState({doc: initVals})
                    })
                    .catch(err => {console.log("Fetch Error: "+err); this.props.history.push("/404")});
            } else {
                this.setState({doc: empty_doc});
            }
        }
        this.checkAuthentication();
        ReactTooltip.rebuild();
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            const user = await this.props.auth.getUser();
            this.setState({ authenticated, user });
        }
    }

    handleRetrieveAddress = (res) => {
        let f = res.features[0];
        let [lon, lat] = f.geometry.coordinates;
        let geo = {
            "lat": lat,
            "lon": lon
        }
        let doc = this.state.doc;
        doc['geo_location'] = geo;
        doc['notes'] = doc['notes']+"1";
        this.setState({doc: doc});
        console.log("Before state: " + JSON.stringify(this.state))
        this.setState({doc: doc, feature: res.features[0], showMinimap: true, showAddressFormExpanded: true})
        console.log("After state: " + JSON.stringify(this.state))
    }

    expandAddressForm(show){
        this.setState({showAddressFormExpanded: show});
    }

    debugDoc(){
        console.log(JSON.stringify(this.state.doc))
    }

    onSubmit = async (values: any, { setSubmitting }: any) => {
        let url = '/api/trax';
        if (this.props.docId){
            url = "/api/trax/"+this.props.docId;
        }
        axios.post(url, this.massageValues(values), {headers: {
            'authorization': `Bearer ${await this.props.auth.getAccessToken()}`
            }
        }).then((res) => {
            console.log("result: "+JSON.stringify(res));
            this.props.history.push("/trax/"+res.data._id);
        }).catch(function (error) {
            alert(error);
            setSubmitting(false);
        });
        setSubmitting(true);
    };


    massageValues = (values) => {
        console.log("values: "+values);
        console.log("before massage: "+JSON.stringify(values));
        // build candidates_combined
        values.candidates_combined = [];
        values.candidates_combined = values.candidates_combined.concat(values.candidate_positive);
        values.candidates_combined = values.candidates_combined.concat(values.candidate_negative);
        values.candidates_combined = values.candidates_combined.concat(values.candidate);
        values.candidates_combined = values.candidates_combined.filter((v) => v);

        // convert mediaLinks
        let media_links = [];
        for (const m of trax_media_options){
            let fieldname = 'media_' + m.value;
            if (values[fieldname]){
                let l = {
                    type: m.value,
                    link: values[fieldname]
                }
                media_links.push(l);
                delete values[fieldname];
            }
        }
        values.media_links = media_links;

        // write geo
        let doc = this.state.doc;
        let geo = doc['geo_location']
        console.log("geo from doc:" +JSON.stringify(geo));
        console.log("initial values.geo_location: "+ JSON.stringify(values.geo_location))
        values.geo_location = geo
        console.log("after massage: "+JSON.stringify(values));
        return values;
    };

    renderForm = (props: any) => {
        const campaign = props.values.campaign;
        return (
            <Form use="bootstrap4" css={{control: "form-control-sm"}}>
                {/*<Form use="bootstrap4" horizontal css={{label: "col-md-2 col-form-label-sm", control: "col-md-9 form-control-sm", help:"invisible"}}>*/}
                <Card>
                    <Card.Header className="bg-secondary text-white">Music Details</Card.Header>
                    <Card.Body>
                        <Field label="Song Title" name="song_title"/>
                        <Field label="Performer / Composer" name="artist"
                               help="In the case of parodies, list the creator of the parody first, and the original artist second."/>
                        <Field label="Genre" custom={MultiSelect} placeholder="Select Genre(s)" options={trax_genres}
                               name='genre' value={this.state.doc ? this.state.doc.genre : ""}/>
                        <Field label="Type of Music" custom={MultiSelect} placeholder="Select Music Type(s)" options={trax_music_type_options}
                               name="music_type" value={this.state.doc ? this.state.doc.music_type : ""}/>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="bg-secondary text-white">Event Details</Card.Header>
                    <Card.Body>
                        <Field label="Title of Event/Media" name="event_title"/>
                        <Field label="Type of Event/Media" custom={MultiSelect} placeholder="Select Event Type(s)" options={trax_event_type_options}
                               name="event_type" value={this.state.doc ? this.state.doc.event_type : ""}/>
                        <Field date label="Date" name="date"/>
                        <div className="form-group form-row">
                            <div className="col-md-7">
                                <AddressAutofill onRetrieve={this.handleRetrieveAddress} accessToken={this.access_token}>
                                    <Field label="Address" name="address" placeholder="Start typing address, e.g. 123 Main..." autoComplete="street-address"/>
                                </AddressAutofill>
                                {!this.state.showAddressFormExpanded &&
                                    <button id="manual-entry" className="link border-bottom " onClick={() => this.expandAddressForm(true)}>Enter address manually</button>
                                }
                                <div className="form-group form-row" style={{display: this.state.showAddressFormExpanded ? 'flex' : 'none'}}>
                                    <div className="col-md-6"><Field label="City" name="city" placeholder="City" autoComplete="address-level2"/></div>
                                    <div className="col-md-4"><Field label="State" name="state" placeholder="State" autoComplete="address-level1"/></div>
                                    <div className="col-md-2"><Field label="Zip Code" name="zipcode" placeholder="Zip Code" autoComplete="postal-code"/></div>
                                </div>
                                {/*<div className="form-group form-row" style={{display: this.state.showAddressFormExpanded ? 'flex' : 'none'}}>*/}
                                {/*    <div className="col"><Field label="Latitude" name="geo_location.lat" /></div>*/}
                                {/*    <div className="col"><Field label="Longitude" name="geo_location.lon"/></div>*/}
                                {/*</div>*/}
                            </div>
                            <div id="minimap-container" className="col-md-5">
                                <AddressMinimap feature={this.state.feature} accessToken={this.access_token} show={this.state.showMinimap}/>
                            </div>
                        </div>
                        {/*<div id="debugDoc" className="link" onClick={() => this.debugDoc()}>Debug Doc</div>*/}
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="bg-secondary text-white">Candidate Details</Card.Header>
                    <Card.Body>
                        <Field select label="Campaign" options={trax_campaign_options} placeholder="Select Campaign"
                               name="campaign" help="Note: Changing the selected campaign will update the lists of Candidates available below."/>
                        <div className="form-group form-row">
                            <div className="col"><Field label="Candidates SUPPORTED" custom={MultiSelect}
                                                        placeholder="Select Candidate(s)"
                                                        options={trax_candidate_options_by_campaign[campaign]} name='candidate_positive'/>
                            </div>
                            <div className="col"><Field label="Candidates OPPOSED" custom={MultiSelect}
                                                        placeholder="Select Candidate(s)"
                                                        options={trax_candidate_options_by_campaign[campaign]} name='candidate_negative'/>
                            </div>
                            <div className="col"><Field label="Candidates OTHER" custom={MultiSelect}
                                                        placeholder="Select Candidate(s)"
                                                        options={trax_candidate_options_by_campaign[campaign]} name='candidate'/></div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="bg-secondary text-white">Media Links</Card.Header>
                    <Card.Body>
                        <Field label="YouTube" name="media_youtube"/>
                        <Field label="Twitter (X)" name="media_twitter"/>
                        <Field label="Spotify" name="media_spotify"/>
                        <Field label="Facebook" name="media_facebook"/>
                        <Field label="Instagram" name="media_instagram"/>
                        <Field label="Soundcloud" name="media_soundcloud"/>
                        <Field label="CSPAN" name="media_cspan"/>
                        <Field label="Vimeo" name="media_vimeo"/>
                        <Field label="Tumblr" name="media_tumblr"/>
                        <Field label="Other" name="media_other"/>
                    </Card.Body>

                </Card>
                <Card className="mb-3">
                    <Card.Header className="bg-secondary text-white">Other</Card.Header>
                    <Card.Body>
                        <Field textarea name="notes" rows="5"/>
                    </Card.Body>
                </Card>
                <Button type="submit" disabled={props.isSubmitting}/>
            </Form>
        );
    };

    render() {
        return (
            <FormContainer  enableReinitialize={true} initialValues={this.state.doc} validationSchema={schema} onSubmit={this.onSubmit} render={this.renderForm}/>
        )
    }
}
export default compose(withAuth, withRouter)(DocumentEditor);


import * as React from "react";

import {
    SearchkitManager,
    SearchkitProvider,
    SearchkitComponent,
    SearchBox,
    Pagination,
    RefinementListFilter,
    Layout,
    TopBar,
    LayoutBody,
    LayoutResults,
    ActionBar,
    ActionBarRow,
    HitsStats,
    GroupedSelectedFilters,
    ResetFilters,
    SideBar,
    ViewSwitcherHits,
    ViewSwitcherToggle,
    PageSizeAccessor,
    SortingSelector,
    ViewOptionsAccessor,
    NoHits,
    QueryAccessor
} from "searchkit";

import {DateRangeFilter, DateRangeCalendar} from "searchkit-datefilter";
import {Map, TileLayer, Marker, Popup} from "react-leaflet";
import { NonblankCheckboxItem } from "./CustomComponents";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { renderJoinedIfArray as renderValue } from '../../utils';

import 'searchkit-datefilter/release/theme.css';
import 'searchkit/release/theme.css';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faThumbsUp, faThumbsDown} from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from 'react-tooltip';
import LoginButton from "../LoginButton";
import {getPartyForCandidate} from "../../utils/trax_metadata";
require('react-leaflet-markercluster/dist/styles.min.css');

const searchkit = new SearchkitManager("/api/trax");

const osmTiles = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

let pageSizeAccessor = searchkit.getAccessorByType(PageSizeAccessor);

class TraxHitsTable extends React.Component {
    renderCandidates(campaign, candidates_raw, candidate_positive_raw, candidate_negative_raw){
        if (campaign && candidates_raw){
            const candidates = (candidates_raw instanceof Array) ? candidates_raw : [candidates_raw];
            const candidates_pos = (candidate_positive_raw instanceof Array) ? candidate_positive_raw : [candidate_positive_raw];
            const candidates_neg = (candidate_negative_raw instanceof Array) ? candidate_negative_raw : [candidate_negative_raw];
            return (
                <div>{candidates.map((c, i) => {
                    const party = getPartyForCandidate(campaign, c);
                    var sentiment = <FontAwesomeIcon icon={faQuestionCircle} color="grey" data-tip="Sentiment neutral or unknown"/>
                    if (candidates_pos.includes(c))
                        sentiment = <FontAwesomeIcon icon={faThumbsUp} color="green" data-tip="Positive Sentiment - This event SUPPORTS this candidate"/>
                    else if (candidates_neg.includes(c))
                        sentiment = <FontAwesomeIcon icon={faThumbsDown} color="darkred" data-tip="Negative Sentiment - this event OPPOSES this candidate"/>
                    if (party) {
                        // let icon = "3";
                        // switch (party.initial) {
                        //     case "R":
                        //         icon = <FontAwesomeIcon icon={faRepublican}/>;
                        //         break;
                        //     case "D":
                        //         icon = <FontAwesomeIcon icon={faDemocrat}/>;
                        //         break;
                        // }
                        return (
                            <span key={i}>{i > 0 && ", "}<span style={{whiteSpace: "nowrap"}}>{sentiment}{c}<sup
                                style={{fontWeight: "bold", color: (party.color) ? party.color : "goldenrod"}}
                                data-tip={party.name}>{party.initial}</sup></span></span>
                        )
                    } else {
                        return <span key={i}>{i > 0 && ", "}<span style={{whiteSpace: "nowrap"}}>{sentiment}{c}</span></span>
                    }
                })}
                </div>
            )
        } else {
            return undefined;
        }
    }
    componentDidUpdate(): void {
        ReactTooltip.rebuild();
    }

    render(){
        const { hits } = this.props;
        return (
            <div style={{width: '100%', boxSizing: 'border-box', padding: 8}}>
                <ReactTooltip effect="solid"/>
                <table className="sk-table sk-table-striped" style={{width: '100%', boxSizing: 'border-box'}}>
                    <thead>
                    <tr>
                        {/*<th nowrap="nowrap">ID</th>*/}
                        <th nowrap="nowrap">Title <FontAwesomeIcon icon={faQuestionCircle} data-tip="The title of the music used."/></th>
                        <th nowrap="nowrap">Performer/Composer <FontAwesomeIcon icon={faQuestionCircle} data-tip="The name(s) listed here is the artist and/or performer. In the case of parodies, the creator of the parody is listed first, and the name of the original artist is listed second. Composers are generally not listed in the case of folk songs or well-known patriotic songs."/></th>
                        {/*<th>Type of Music <FontAwesomeIcon icon={faQuestionCircle} title="Insert some help text here." className="float-right"/></th>*/}
                        {/*<th>Genre <FontAwesomeIcon icon={faQuestionCircle} title="Insert some help text here." className="float-right"/></th>*/}
                        <th nowrap="nowrap">Candidates <FontAwesomeIcon icon={faQuestionCircle} data-tip="Candidates included in the event.  A thumbs-up or thumbs-down icon indicates whether a candidate was supported or opposed in this event."/></th>
                        {/*<th>Party <FontAwesomeIcon icon={faQuestionCircle} title="Insert some help text here." className="float-right"/></th>*/}
                        <th nowrap="nowrap">Title of Event/Media</th>
                        <th nowrap="nowrap">Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {hits.map(hit => (
                        <tr key={hit._id}>
                            {/*<td><Link to={'/trax/'+hit._id}>{hit._id}</Link></td>*/}
                            <td><Link to={'/trax/'+hit._id}>{(hit._source.song_title) ? hit._source.song_title : <i>(blank)</i>}</Link></td>
                            <td>{renderValue(hit._source.artist)}</td>
                            {/*<td>{renderValue(hit._source.music_type)}</td>*/}
                            {/*<td>{renderValue(hit._source.genre)}</td>*/}
                            <td>{this.renderCandidates(hit._source.campaign, hit._source.candidates_combined, hit._source.candidate_positive, hit._source.candidate_negative)}</td>
                            {/*<td>{renderValue(hit._source.candidate)}</td>*/}
                            {/*<td>{hit._source.party}</td>*/}
                            <td>{hit._source.event_title}</td>
                            <td nowrap="nowrap">{hit._source.date}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

class TraxHitsMap extends React.Component {
    renderCandidates(campaign, candidates_raw, candidate_positive_raw, candidate_negative_raw){
        if (campaign && candidates_raw){
            const candidates = (candidates_raw instanceof Array) ? candidates_raw : [candidates_raw];
            const candidates_pos = (candidate_positive_raw instanceof Array) ? candidate_positive_raw : [candidate_positive_raw];
            const candidates_neg = (candidate_negative_raw instanceof Array) ? candidate_negative_raw : [candidate_negative_raw];
            return (
                <div>{candidates.map((c, i) => {
                    const party = getPartyForCandidate(campaign, c);
                    var sentiment = <FontAwesomeIcon icon={faQuestionCircle} color="grey" data-tip="Sentiment neutral or unknown"/>
                    if (candidates_pos.includes(c))
                        sentiment = <FontAwesomeIcon icon={faThumbsUp} color="green" data-tip="Positive Sentiment - This event SUPPORTS this candidate"/>
                    else if (candidates_neg.includes(c))
                        sentiment = <FontAwesomeIcon icon={faThumbsDown} color="darkred" data-tip="Negative Sentiment - this event OPPOSES this candidate"/>
                    if (party) {
                        // let icon = "3";
                        // switch (party.initial) {
                        //     case "R":
                        //         icon = <FontAwesomeIcon icon={faRepublican}/>;
                        //         break;
                        //     case "D":
                        //         icon = <FontAwesomeIcon icon={faDemocrat}/>;
                        //         break;
                        // }
                        return (
                            <span key={i}>{i > 0 && ", "}<span style={{whiteSpace: "nowrap"}}>{sentiment}{c}<sup
                                style={{fontWeight: "bold", color: (party.color) ? party.color : "goldenrod"}}
                                data-tip={party.name}>{party.initial}</sup></span></span>
                        )
                    } else {
                        return <span key={i}>{i > 0 && ", "}<span style={{whiteSpace: "nowrap"}}>{sentiment}{c}</span></span>
                    }
                })}
                </div>
            )
        } else {
            return undefined;
        }
    }
    componentDidMount() {
        pageSizeAccessor.setSize(10000);
        searchkit.performSearch();
    }
    componentWillUnmount() {
        pageSizeAccessor.setSize(20);
        searchkit.performSearch();
    }

    render() {
        const { hits } = this.props;
        const position = [39.8283, -98.5795];
        return (
            <div style={{width: '100%', boxSizing: 'border-box', padding: 8}}>
                <Map center={position} zoom={4} maxZoom={18}>
                    <TileLayer attribution={osmAttrib} url={osmTiles}/>
                    <MarkerClusterGroup>
                        {hits.map(hit => (hit._source.geo_location) ? (
                            <Marker position={hit._source.geo_location} key={hit._id}>
                                <Popup>
                                    <table className="sk-table sk-table-striped">
                                        <tbody>
                                        {/*<tr><th>ID</th><td>{hit._id}</td></tr>*/}
                                        <tr><th>Title</th><td><Link to={'/trax/'+hit._id}>{(hit._source.song_title) ? hit._source.song_title : <i>(blank)</i>}</Link></td></tr>
                                        <tr><th>Performer/Composer</th><td>{renderValue(hit._source.artist)}</td></tr>
                                        {/*<tr><th>Music Type</th><td>{renderValue(hit._source.music_type)}</td></tr>*/}
                                        {/*<tr><th>Genre</th><td>{renderValue(hit._source.genre)}</td></tr>*/}
                                        <tr><th>Candidate</th><td>{this.renderCandidates(hit._source.campaign, hit._source.candidates_combined, hit._source.candidate_positive, hit._source.candidate_negative)}</td></tr>
                                        <tr><th>Event/Media</th><td>{hit._source.event_title}</td></tr>
                                        {/*<tr><th>Party</th><td>{hit._source.party}</td></tr>*/}
                                        <tr><th>Date</th><td>{hit._source.date}</td></tr>
                                        </tbody>
                                    </table>
                                </Popup>
                            </Marker>
                        ) : undefined )}
                    </MarkerClusterGroup>
                </Map>
            </div>
        )
    }
}

class SearchApp extends SearchkitComponent {

    getViewOptionsSwitcherAccessor(){
        return this.searchkit.getAccessorByType(ViewOptionsAccessor);
    }

    render() {
        let viewOptionsAccessor = this.getViewOptionsSwitcherAccessor();
        let showSortSelector = true;
        if (viewOptionsAccessor){
            showSortSelector = (viewOptionsAccessor.getSelectedOption().key !== 'map');
        }
        return (
            <Layout style={{background: "none"}}>
                <TopBar>
                    <div className="my-logo" style={{color: '#fff', flex: '0 0 250px', padding: '13px 20px', marginRight: '15px', lineHeight: '16px', textAlign: 'center'}}>Trail Trax</div>
                    <SearchBox queryFields={["song_title^5", "artist^3", "candidates_combined^3", "event_title^3", "notes"]}/>
                    <div style={{marginLeft: 15}}><LoginButton /></div>
                </TopBar>
                <LayoutBody>
                    <SideBar>
                        <DateRangeFilter id="DateFilter" title="Date Range" fromDateField="date" toDateField="date" calendarComponent={DateRangeCalendar} />
                        {/*<MenuFilter id="Party" title="Party" field="party" itemComponent={NonblankMenuItem}/>*/}
                        <RefinementListFilter id="Genre" title="Genre" field="genre" operator="OR" size={10} itemComponent={NonblankCheckboxItem}/>
                        <RefinementListFilter id="Type" title="Type of Music" field="music_type" operator="OR" size={5} itemComponent={NonblankCheckboxItem}/>
                        <RefinementListFilter id="Campaign" title="Campaign" field="campaign" operator="OR" itemComponent={NonblankCheckboxItem}/>
                        <RefinementListFilter id="Candidate" title="Candidate" field="candidates_combined" operator="OR" size={5} itemComponent={NonblankCheckboxItem}/>
                        <RefinementListFilter id="Event_Type" title="Type of Event" field="event_type" operator="OR" size={5} itemComponent={NonblankCheckboxItem}/>
                    </SideBar>
                    <LayoutResults>
                        <ActionBar>
                            <ActionBarRow>
                                <GroupedSelectedFilters/>
                                <ResetFilters/>
                                <HitsStats translations={{
                                    "hitstats.results_found":"{hitCount} results found"
                                }}/>
                                {showSortSelector && <SortingSelector options={[
                                    {label: "Relevance", field: "_score", order: "desc"},
                                    {label: "Most Recent", field: "date", order: "desc", defaultOption: true},
                                    {label: "Oldest", field: "date", order: "asc"},
                                    {label: "Date Created", field: "_created.datetime", order: "desc"}
                                ]}/>}
                                <ViewSwitcherToggle/>
                            </ActionBarRow>
                        </ActionBar>
                        <ViewSwitcherHits
                            highlightFields={["song_title", "artist", "candidate"]}
                            hitComponents={[
                                {key:"table", title:"Table", listComponent: TraxHitsTable, defaultOption: true },
                                {key:"map", title:"Map", listComponent: TraxHitsMap}
                            ]}
                        />
                        <NoHits suggestionsField="song_title"/>
                        {/*<Hits hitsPerPage={20} mod="sk-hits-list" listComponent={TraxHitsMap}/>*/}
                        <Pagination showNumbers={true} />
                    </LayoutResults>
                </LayoutBody>
            </Layout>
        )
    }
}

export const SearchPage = () => (
    <SearchkitProvider searchkit={searchkit}>
        <SearchApp/>
    </SearchkitProvider>
);
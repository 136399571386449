import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import LoginButton from "./LoginButton";

export const TraxNavbar = (props) => (
    <Navbar bg="primary" variant="dark" expand="md" style={{marginLeft: -15, marginRight: -15}}>
        <Navbar.Brand href="http://www.traxonthetrail.com/">Trax on the Trail</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Item><Nav.Link href="https://www.traxonthetrail.com/">Home</Nav.Link></Nav.Item>
                <Nav.Item className="active"><Nav.Link href="/trax">Trax Database</Nav.Link></Nav.Item>
                <Nav.Link href="https://www.traxonthetrail.com/essays-and-interviews">Essays & Interviews</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/classroom-materials">Classroom Materials</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/podcast">Podcast</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/bibliography">Bibliography</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/press">News</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/staff-and-contributors">Staff and Contributors</Nav.Link>
                <Nav.Link href="https://www.traxonthetrail.com/faq">FAQ</Nav.Link>
            </Nav>
            <Nav>
                <LoginButton/>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);
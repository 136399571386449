import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faThumbsUp, faThumbsDown, faTrashAlt, faEdit} from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from 'react-tooltip';
import {TraxNavbar} from "./TraxNavbar";
import { withAuth } from '@okta/okta-react';
import {withRouter} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {getLabelForMediaType, getPartyForCandidate, trax_fields} from "../utils/trax_metadata";
import {renderJoinedIfArray, truncateString} from "../utils";
import {Link} from "react-router-dom";
import axios from "axios";
import { compose } from 'recompose';
import Alert from "react-bootstrap/Alert";
import {Map, TileLayer, Marker, Popup} from "react-leaflet";

const osmTiles = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

function makeLinkFromURL(value){
    return <a target="_blank" rel="noopener noreferrer" href={value}>{truncateString(value, 50)}</a>;
}

class DocumentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: {_id: "", _source: {}},
            rows: [],
            mediaLinks: [],
            geo_location: null,
            authenticated: null,
            user: null,
            showDeleteModal: false
        }
    }
    componentDidMount(): void {
        const url = "/api/trax/"+ this.props.docId;
        fetch(url)
            .then(res => res.json())
            .then(doc => this.setState({doc: doc, rows: this.getRowsForDoc(doc), mediaLinks: this.getMediaLinksForDoc(doc), geo_location: doc._source.geo_location}))
            .catch(err => {console.log("Fetch Error: "+err); this.props.history.push("/404")});
        this.checkAuthentication();
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.docId !== this.props.docId){
            const url = "/api/trax/"+ this.props.docId;
            fetch(url)
                .then(res => res.json())
                .then(doc => this.setState({doc: doc, rows: this.getRowsForDoc(doc), mediaLinks: this.getMediaLinksForDoc(doc), geo_location: doc._source.geo_location}))
                .catch(err => {console.log("Fetch Error: "+err); this.props.history.push("/404")});;
        }
        this.checkAuthentication();
        ReactTooltip.rebuild();
    }

    getMediaLinksForDoc(doc): Array {
        if (doc){
            let toRet = [];
            const record = doc._source;
            const links = record['media_links'];
            if (links && links instanceof Array && links.length >= 1){
                for(const l of links){
                    let r = {name: getLabelForMediaType(l["type"]), value: l["link"]};
                    toRet.push(r);
                }
            }
            return toRet;
        } else {
            return [];
        }
    }
    getRowsForDoc(doc): Array {
        if (doc){
            let rows = [];
            let record = doc._source;
            for (const f of trax_fields){
                const {db_col, visible, multiple_values, make_link, friendly_name, tooltip} = f;
                if (visible && record[db_col] && !(record[db_col] instanceof Array && record[db_col].length < 1)){
                    let value = record[db_col];
                    let r = {name: friendly_name, tooltip: tooltip, value: value};
                    if (db_col === "candidates_combined"){
                        r.renderFunction = this.renderCandidateRowFromRow;
                        r.campaign = record.campaign;
                        r.candidate_positive = record.candidate_positive;
                        r.candidate_negative = record.candidate_negative;
                    } else if (db_col === "media_links"){
                        let thelist = [];
                        for (const link of record[db_col]){
                            thelist.push(<li key={link["link"]}>{link.type}: {link["link"]}</li>)
                        }
                        r.value = <ul>{thelist}</ul>;
                    } else {
                        if (multiple_values) {
                            r.value = renderJoinedIfArray(value);
                        }
                        if (make_link) {
                            r.value = makeLinkFromURL(value);
                        }
                    }
                    rows.push(r);
                } else {
                    // console.log("[%s] ignored: %s", db_col, record[db_col]);
                }
            }
            return rows;
        } else {
            throw new Error("No document");
        }
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            const user = await this.props.auth.getUser();
            this.setState({ authenticated, user });
        }
    }

    renderRecordHistory(){
        const created = this.state.doc._source._created;
        if (!created){
            return null;
        }
        let modified = false;
        if (this.state.doc._source._modified){
            modified = this.state.doc._source._modified[this.state.doc._source._modified.length - 1];
        }
        return (
            <span className="text-right font-weight-light font-italic" style={{marginLeft: "auto"}}>
                Created by <span data-tip={created.user.email}>{created.user.name}</span>, {created.datetime}<br />
                {modified && <span>Last Modified by <span data-tip={modified.user.email}>{modified.user.name}</span>, {modified.datetime} </span>}
            </span>
        )
    }

    renderTableRows(){
        return this.state.rows.map((row) => {
            const {name, tooltip, value} = row;
            return (
                <tr key={this.state.doc._id + "-"+name}><th nowrap="nowrap">{name} {tooltip && <FontAwesomeIcon icon={faQuestionCircle} data-html={true} data-tip={tooltip}/>}</th><td>{row.renderFunction ? row.renderFunction(row) : value}</td></tr>
            );
        })
    }

    renderMediaLinks() {
        return this.state.mediaLinks.map((row) => {
            const {name, value} = row;
            return (
                <tr key={this.state.doc._id+"-"+value}><th nowrap="nowrap">{name}</th><td>{makeLinkFromURL(value)}</td></tr>
            );
        });
    }

    renderMap(){
        const position = this.state.geo_location;
        return (
            <div style={{width: '100%', boxSizing: 'border-box', padding: 8}}>
                <Map center={position} zoom={8} maxZoom={18}>
                    <TileLayer attribution={osmAttrib} url={osmTiles}/>
                    <Marker position={position}>
                    </Marker>
                </Map>
            </div>
        )
    }


    renderCandidateRowFromRow(row){
        const campaign = row.campaign;
        const candidates = (row.value instanceof Array) ? row.value : [row.value];
        const candidates_pos = (row.candidate_positive instanceof Array) ? row.candidate_positive : [row.candidate_positive];
        const candidates_neg = (row.candidate_negative instanceof Array) ? row.candidate_negative : [row.candidate_negative];
        if (campaign && candidates) {
            return (
                <div>{candidates.map((c, i) => {
                        var sentiment = <FontAwesomeIcon icon={faQuestionCircle} color="grey"  data-tip="Sentiment neutral or unknown"/>
                        if (candidates_pos.includes(c))
                            sentiment = <FontAwesomeIcon icon={faThumbsUp} color="green"  data-tip="Positive Sentiment - This event SUPPORTS this candidate"/>
                        else if (candidates_neg.includes(c))
                            sentiment = <FontAwesomeIcon icon={faThumbsDown} color="darkred"  data-tip="Negative Sentiment - this event OPPOSES this candidate"/>
                        const party = getPartyForCandidate(campaign, c);
                        if (party) {
                            return (
                                <span key={i}>{i > 0 && ", "}<span style={{whiteSpace: "nowrap"}}>{sentiment}{c}<sup
                                    style={{fontWeight: "bold", color: (party.color) ? party.color : "goldenrod"}}
                                    data-tip={party.name}>{party.initial}</sup></span></span>
                            );
                        } else {
                            return (
                                <span key={i}>{i > 0 && ", "}{sentiment}{c}</span>
                            )
                        }
                })}</div>
            );
        } else {
            return undefined;
        }
    }

    handleDeleteDialogClose = () => {
        this.setState({showDeleteModal: false});
    };

    handleDeleteDialogConfirm = async () => {
        let url = '/api/trax';
        if (this.props.docId){
            url = "/api/trax/"+this.props.docId;
        }
        axios.delete(url, {headers: {
                'authorization': `Bearer ${await this.props.auth.getAccessToken()}`,
                data: this.state.doc
            }
        }).then((res) => {
            console.log("deletion result: "+JSON.stringify(res));
            alert("Record deleted successfully.");
            this.props.history.push("/trax");
        }).catch(function (error) {
            alert(error);
            this.setState({showDeleteModal: false});
        });
    };

    render(){
        const hit = this.state.doc;
        return (
            <div className="wrapper">
                <div className="container">
                    <TraxNavbar/>
                    <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteDialogClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this record?  This action cannot be undone.</Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleDeleteDialogClose}>Cancel</Button>
                            <Button variant="danger" onClick={this.handleDeleteDialogConfirm}>Yes, Delete</Button>
                        </Modal.Footer>
                    </Modal>
                    <Alert variant="info" className="mt-1">Note: This database is still under construction.  Content and functionality may change without warning.</Alert>
                    <div className="row no-gutters"><h4 className="float-left">{hit._source.song_title}</h4>{this.state.authenticated && <span style={{marginLeft:"auto"}}><Link to={"/trax/"+this.props.docId+"/edit"}><Button className="btn btn-primary"><FontAwesomeIcon icon={faEdit}/>&nbsp;Edit</Button></Link>&nbsp;<Button variant="danger" onClick={() => this.setState({showDeleteModal:true})}><FontAwesomeIcon icon={faTrashAlt}/>&nbsp;Delete</Button></span>}</div>
                    <div className="row no-gutters table-responsive"><table className="table table-striped">
                        <tbody>
                        <tr style={{display: "none"}}><th width="5%">ID</th><td>{hit._id}</td></tr>
                        {this.renderTableRows()}
                        {this.renderMediaLinks()}
                        </tbody>
                    </table></div>
                    {this.state.geo_location && this.renderMap()}
                    {this.state.authenticated && <div className="row no-gutters">{this.renderRecordHistory()}</div>}
                    <ReactTooltip effect="solid" multiline={true} className="tooltip"/>
                </div>
            </div>
        )
    }
}

export default compose(withAuth, withRouter)(DocumentView);
export const trax_genres = [
    // {value: "alternative", label: "alternative"},
    {value: "avant-garde", label: "avant-garde"},
    {value: "blues", label: "blues"},
    {value: "children's", label: "children's"},
    {value: "classical", label: "classical"},
    {value: "comedy/spoken", label: "comedy/spoken"},
    {value: "country", label: "country"},
    {value: "easy listening", label: "easy listening"},
    {value: "electronic", label: "electronic"},
    {value: "folk", label: "folk"},
    {value: "holiday", label: "holiday"},
    {value: "international", label: "international"},
    {value: "jazz", label: "jazz"},
    {value: "latin", label: "Latin"},
    {value: "new age", label: "new age"},
    // {value: "patriotic", label: "patriotic"},
    {value: "pop/rock", label: "pop/rock"},
    {value: "r&b", label: "R&B"},
    {value: "rap", label: "rap"},
    {value: "reggae", label: "reggae"},
    {value: "religious", label: "religious"},
    // {value: "spoken word", label: "spoken word"},
    {value: "stage & screen", label: "stage & screen"},
    // {value: "traditional", label: "traditional"},
    {value: "vocal", label: "vocal"}
];
export const trax_fields = [
    {db_col: "id", visible: false, friendly_name: "ID", tooltip: ""},
    {db_col: "song_title", visible: true, friendly_name: "Music Title", tooltip: "The title of the music/song.  If the song occurs as part of a specific event, the event title is provided separately."},
    {db_col: "artist", visible: true, multiple_values: true, friendly_name: "Performer / Composer", tooltip: "The name(s) listed here is the artist and/or performer. In the case of parodies, the creator of the parody is listed first, and the name of the original artist is listed second. Composers are generally not listed in the case of folk songs or well-known patriotic songs."},
    {db_col: "genre", visible: true, multiple_values: true, friendly_name: "Genre", tooltip: "A category of music; the genres in the database are based on information located at allmusic.com."},
    {db_col: "music_type", visible: true, multiple_values: true, friendly_name: "Type of Music", tooltip: "<p align=\"left\"><b>chant</b>: Moments at live campaign events where citizens musicalize the candidate's name or voice support for a concept. Collective rhythm is key here.</p>\n" +
            "<p align=\"left\"><b>newly composed</b>: A song created for a specific candidate, party, or issue, usually with a candidate- or campaign-specific text. These may be solicited or unsolicited by the official campaign.</p>\n" +
            "<p align=\"left\"><b>parody</b>: A song that features a preexisting tune set to a candidate-, issue-, or party-specific text. These can be created by official campaign teams or unaffiliated persons/groups.</p>\n" +
            "<p align=\"left\"><b>preexisting</b>: An unaltered popular song already known to the public that is used in various campaign contexts, typically rallies, conventions, and campaign stops.</p>\n" +
            "<p align=\"left\"><b>remix</b>: A different version of a musical recording produced by remixing.</p>\n" +
            "<p align=\"left\"><b>songify</b>: A remixed version of a candidate’s speeches or appearances used as the foundation for a new musical composition.</p>\n" +
            "<p align=\"left\"><b>underscore</b>: Background music, usually instrumental. Underscore is typically used in advertisements and candidate videos. In most instances, titles and composers cannot be identified.</p>\n" +
            "<p align=\"left\"><b>other</b>: Music that does not fit into one of the above categories is simply classified as 'other.'</p>"},
    {db_col: "event_title", visible: true, friendly_name: "Title of Event/Media", tooltip: ""},
    {db_col: "event_type", visible: true, multiple_values: true, friendly_name: "Type of Event/Media", tooltip: "<p align=\"left\"><b>advertisement</b>: An informational audio or video segment designed to sell a candidate, attack a candidate, or highlight an issue; usually financed by the official campaign, party, or a PAC (political action committee).</p>\n" +
            "<p align=\"left\"><b>campaign launch</b>: The event (typically a rally) where a candidate formally announces his/her candidacy.</p>\n" +
            "<p align=\"left\"><b>campaign rally</b>: Large-scale candidate- or party-sponsored event, typically held in an outdoor space or arena where the candidate appears to give a speech.\n" +
            "<p align=\"left\"><b>campaign stop</b>: Similar to a campaign rally, but typically in a smaller venue such as a coffee shop.</p>\n" +
            "<p align=\"left\"><b>concert</b>: Music performance organized by a person or group unaffiliated with an official campaign where there is no explicit mention of fundraising.</p>\n" +
            "<p align=\"left\"><b>convention</b>: Large conference or assembly sponsored by a political organization.</p>\n" +
            "<p align=\"left\"><b>daytime talk show</b>: Daytime news/entertainment-oriented shows (e.g., Ellen, The View).</p>\n" +
            "<p align=\"left\"><b>fundraiser</b>: Any event sponsored by a campaign, party, or unaffiliated person/group that is intended to raise funds for a campaign</p>\n" +
            "<p align=\"left\"><b>fundraising concert</b>: Music performance organized to raise funds for a campaign (typically by a person or group unaffiliated with the official campaign).</p>\n" +
            "<p align=\"left\"><b>late show</b>: Evening programming; including variety or satirical news shows (e.g., Saturday Night Live, The Stephen Colbert Show).</p>\n" +
            "<p align=\"left\"><b>mock or parody advertisement</b>: Video segments created by a person or group unaffiliated with the campaign which mimic the aural and visual rhetoric of their official counterparts. These are often posted on websites like CollegeHumor or YouTube. When a genre is indicated, the genre listed is in reference to the original tune.</p>\n" +
            "<p align=\"left\"><b>online media</b>: Catchall term for any media (audio or visual) that is posted on the Internet. This category may include videos posted on Facebook or YouTube or tracks posted on SoundCloud, Spotify, or other music streaming sites. Advertisements (Internet or television) and video clips that originated on television are categorized separately.</p>\n" +
            "<p align=\"left\"><b>playlist</b>: <ol>\n" +
            "<li>A playlist created by a candidate and shared with the public via a music-streaming platform such as Spotify. These lists may be the candidate’s personal music choices or compilations of music that they use at live events.</li>\n" +
            "<li>A candidate- or campaign-themed playlist created by a person or group unaffiliated with the official campaign, often under the guise of “suggesting” music for a candidate. These playlists may also be a musical tribute to a candidate, or a thinly veiled attack.</li></ol>\n" +
            "<p align=\"left\"><b>protest</b>: Events that are held outside of official campaign spaces; typically organized by people who hold opposing viewpoints from the candidate appearing in the official space.</p>\n" +
            "<p align=\"left\"><b>town hall meeting</b>: Candidate Q&amp;A; generally held at smaller venues.</p>\n" +
            "<p align=\"left\"><b>other</b>: General designation for events that do not fit the above descriptions. These can include conferences, meetings, summits, festivals, or other types of events.</p>"},
    {db_col: "date", visible: true, friendly_name: "Date", tooltip: ""},
    {db_col: "campaign", visible: true, friendly_name: "Campaign", tooltip: ""},
    {db_col: "candidates_combined", visible: true, multiple_values: true, friendly_name: "Candidates", tooltip: "Candidates included in the event.  A thumbs-up or thumbs-down icon indicates whether a candidate was supported or opposed in this event."},
    {db_col: "party", visible: false, friendly_name: "Party", tooltip: ""},
    {db_col: "address", visible: true, friendly_name: "Address", tooltip: ""},
    {db_col: "city", visible: true, friendly_name: "City", tooltip: ""},
    {db_col: "state", visible: true, friendly_name: "State", tooltip: ""},
    {db_col: "media_links", visible: false, multiple_values: true, friendly_name: "Media Links"},
    {db_col: "notes", visible: true, friendly_name: "Notes", tooltip: "The “Notes” section is where the Trax on the Trail staff provides additional information regarding the music, event, or media."},
];

export const trax_media_options = [
    {value: "cspan", label: "CSPAN"},
    {value: "facebook", label: "Facebook"},
    {value: "instagram", label: "Instagram"},
    {value: "soundcloud", label: "SoundCloud"},
    {value: "spotify", label: "Spotify"},
    {value: "tumblr", label: "Tumblr"},
    {value: "twitter", label: "Twitter"},
    {value: "vimeo", label: "Vimeo"},
    {value: "youtube", label: "YouTube"},
    {value: "other", label: "General Media"}
];
export function getLabelForMediaType(type){
    for (const o in trax_media_options){
        if (trax_media_options[o].value === type){
            return trax_media_options[o].label;
        }
    }
    return null;
}

export const trax_music_type_options = [
    {value: "chant", label: "chant"},
    {value: "newly composed", label: "newly composed"},
    {value: "other", label: "other"},
    {value: "parody", label: "parody"},
    {value: "preexisting", label: "preexisting"},
    {value: "remix", label: "remix"},
    {value: "songify", label: "songify"},
    {value: "underscore", label: "underscore"}
];
export const trax_event_type_options = [
    {value: "advertisement", label: "advertisement"},
    {value: "campaign launch", label: "campaign launch"},
    {value: "campaign rally", label: "campaign rally"},
    {value: "campaign stop", label: "campaign stop"},
    {value: "cd", label: "CD"},
    {value: "concert", label: "concert"},
    {value: "convention", label: "convention"},
    {value: "daytime talk show", label: "daytime talk show"},
    {value: "ep", label: "EP"},
    {value: "fundraiser", label: "fundraiser"},
    {value: "fundraising concert", label: "fundraising concert"},
    {value: "late show", label: "late show"},
    {value: "mock or parody advertisement", label: "mock or parody advertisement"},
    {value: "online media", label: "online media"},
    {value: "online news", label: "online news"},
    {value: "organization video", label: "organization video"},
    {value: "other", label: "other"},
    {value: "playlist", label: "playlist"},
    {value: "protest", label: "protest"},
    {value: "score", label: "score"},
    {value: "speech", label: "speech"},
    {value: "summit", label: "summit"},
    {value: "town hall meeting", label: "town hall meeting"}
];

const republican_party = {name: "Republican", initial: "R", color: "red"};
const democratic_party = {name: "Democrat", initial: "D", color: "blue"};

export const trax_campaign_candidates = {
    "2016 Campaign": {
        "Hillary Clinton": { name: "Hillary Clinton", sort_name: "Clinton Hillary", party: democratic_party},
        "Lincoln Chafee": { name: "Lincoln Chafee", sort_name: "Chafee Lincoln", party: democratic_party},
        "Lawrence Lessig": { name: "Lawrence Lessig", sort_name: "Lessig Lawrence", party: democratic_party},
        "Martin O'Malley": { name: "Martin O'Malley", sort_name: "O'Malley Martin", party: democratic_party},
        "Bernie Sanders": { name: "Bernie Sanders", sort_name: "Sanders Bernie", party: democratic_party},
        "Jim Webb": { name: "Jim Webb", sort_name: "Webb Jim", party: democratic_party},
        "Donald Trump": { name: "Donald Trump", sort_name: "Trump Donald", party: republican_party},
        "Jeb Bush": { name: "Jeb Bush", sort_name: "Bush Jeb", party: republican_party},
        "Ben Carson": { name: "Ben Carson", sort_name: "Carson Ben", party: republican_party},
        "Chris Christie": { name: "Chris Christie", sort_name: "Christie Chris", party: republican_party},
        "Ted Cruz": { name: "Ted Cruz", sort_name: "Cruz Ted", party: republican_party},
        "Carly Fiorina": { name: "Carly Fiorina", sort_name: "Fiorina Carly", party: republican_party},
        "Jim Gilmore": { name: "Jim Gilmore", sort_name: "Gilmore Jim", party: republican_party},
        "Lindsey Graham": { name: "Lindsey Graham", sort_name: "Graham Lindsey", party: republican_party},
        "Mike Huckabee": { name: "Mike Huckabee", sort_name: "Huckabee Mike", party: republican_party},
        "Bobby Jindal": { name: "Bobby Jindal", sort_name: "Jindal Bobby", party: republican_party},
        "John Kasich": { name: "John Kasich", sort_name: "Kasich John", party: republican_party},
        "George Pataki": { name: "George Pataki", sort_name: "Pataki George", party: republican_party},
        "Rand Paul": { name: "Rand Paul", sort_name: "Paul Rand", party: republican_party},
        "Rick Perry": { name: "Rick Perry", sort_name: "Perry Rick", party: republican_party},
        "Marco Rubio": { name: "Marco Rubio", sort_name: "Rubio Marco", party: republican_party},
        "Rick Santorum": { name: "Rick Santorum", sort_name: "Santorum Rick", party: republican_party},
        "Scott Walker": { name: "Scott Walker", sort_name: "Walker Scott", party: republican_party},
        "Mark Everson": { name: "Mark Everson", sort_name: "Everson Mark", party: republican_party},
        "Jill Stein": { name: "Jill Stein", sort_name: "Stein Jill", party: {name: "Green", initial: "3"}},
        "Gary Johnson": { name: "Gary Johnson", sort_name: "Johnson Gary", party: {name: "Libertarian", initial: "3"}},
        "Evan McMullin": { name: "Evan McMullin", sort_name: "McMullin Evan", party: {name: "Independent", initial: "3"}},
        "Darrell Castle": { name: "Darrell Castle", sort_name: "Castle Darrell", party: {name: "Constitution Party of the U.S.", initial: "3"}},
        "Gloria LaRiva": { name: "Gloria LaRiva", sort_name: "LaRiva Gloria", party: {name: "Party of Socialism and Liberation", initial: "3"}},
        "Rocky de la Fuente": { name: "Rocky de la Fuente", sort_name: "Fuente Rocky de la", party: {name: "Reform Party USA", initial: "3"}},
        "Emidio \"Mimi\" Soltysik": { name: "Emidio \"Mimi\" Soltysik", sort_name: "Soltysik Emidio \"Mimi\"", party: {name: "Socialist Party USA", initial: "3"}},
        "Alyson Kennedy": { name: "Alyson Kennedy", sort_name: "Kennedy Alyson", party: {name: "Socialist Workers Party", initial: "3"}},
    },
    "2020 Campaign": {
        "Michael Bennet": { name: "Michael Bennet", sort_name: "Bennet Michael", party: democratic_party},
        "Joe Biden": { name: "Joe Biden", sort_name: "Biden Joe", party: democratic_party},
        "Bill de Blasio": { name: "Bill de Blasio", sort_name: "Blasio Bill de", party: democratic_party},
        "Cory Booker": { name: "Cory Booker", sort_name: "Booker Cory", party: democratic_party},
        "Steve Bullock": { name: "Steve Bullock", sort_name: "Bullock Steve", party: democratic_party},
        "Pete Buttigieg": { name: "Pete Buttigieg", sort_name: "Buttigieg Pete", party: democratic_party},
        "Julián Castro": { name: "Julián Castro", sort_name: "Castro Julián", party: democratic_party},
        // "Julian Castro": { name: "Julian Castro", sort_name: "Castro Julian", party: democratic_party},
        "John Delaney": { name: "John Delaney", sort_name: "Delaney John", party: democratic_party},
        "Tulsi Gabbard": { name: "Tulsi Gabbard", sort_name: "Gabbard Tulsi", party: democratic_party},
        "Kamala Harris": { name: "Kamala Harris", sort_name: "Harris Kamala", party: democratic_party},
        "Amy Klobuchar": { name: "Amy Klobuchar", sort_name: "Klobuchar Amy", party: democratic_party},
        "Wayne Messam": { name: "Wayne Messam", sort_name: "Messam Wayne", party: democratic_party},
        "Beto O'Rourke": { name: "Beto O'Rourke", sort_name: "O'Rourke Beto", party: democratic_party},
        "Tim Ryan": { name: "Tim Ryan ", sort_name: "Ryan  Tim", party: democratic_party},
        "Bernie Sanders": { name: "Bernie Sanders", sort_name: "Sanders Bernie", party: democratic_party},
        "Joe Sestak": { name: "Joe Sestak", sort_name: "Sestak Joe", party: democratic_party},
        "Tom Steyer": { name: "Tom Steyer", sort_name: "Steyer Tom", party: democratic_party},
        "Elizabeth Warren": { name: "Elizabeth Warren", sort_name: "Warren Elizabeth", party: democratic_party},
        "Marianne Williamson": { name: "Marianne Williamson", sort_name: "Williamson Marianne", party: democratic_party},
        "Andrew Yang": { name: "Andrew Yang", sort_name: "Yang Andrew", party: democratic_party},
        "Donald Trump": { name: "Donald Trump", sort_name: "Trump Donald", party: republican_party},
        "Mark Sanford": { name: "Mark Sanford", sort_name: "Sanford Mark", party: republican_party},
        "Bill Weld": { name: "Bill Weld ", sort_name: "Weld  Bill", party: republican_party},
        "Joe Walsh": { name: "Joe Walsh", sort_name: "Walsh Joe", party: republican_party},
        "Eric Swalwell": { name: "Eric Swalwell", sort_name: "Swalwell Eric", party: democratic_party},
        "Jay Inslee": {name: "Jay Inslee", sort_name: "Inslee Jay", party: democratic_party},
        "John Hickenlooper": {name: "John Hickenlooper", sort_name: "Hickenlooper John", party: democratic_party},
        "John McAfee": {name: "John McAfee", sort_name: "McAfee John", party: {name: "TBD", initial: "3"}},
        "Kirsten Gillibrand": { name: "Kirsten Gillibrand", sort_name: "Gillibrand Kirsten", party: democratic_party},
        "Mike Gravel": { name: "Mike Gravel", sort_name: "Gravel Mike", party: democratic_party},
        "Seth Moulton": { name: "Seth Moulton", sort_name: "Moulton Seth", party: democratic_party},
        "Adam Seaman": { name: "Adam Seaman", sort_name: "Seaman Adam", party: {name:"American Free Soil Party", initial: "3"}},
        "Phil Collins": { name: "Phil Collins", sort_name: "Collins Phil", party: {name:"Prohibition Party", initial: "3"}},
        "Jeff Mackler": { name: "Jeff Mackler", sort_name: "Mackler Jeff", party: {name:"Socialist Action", initial: "3"}},
    },
    "2024 Campaign": {
        "Donald Trump": { name: "Donald Trump", sort_name: "Trump Donald", party: republican_party},
        "Joe Biden": { name: "Joe Biden", sort_name: "Biden Joe", party: democratic_party},
        "Robert Kennedy": { name: "Robert Kennedy", sort_name: "Kennedy Robert", party: democratic_party},
        "Marianne Williamson": { name: "Marianne Williamson", sort_name: "Williamson Marianne", party: democratic_party},
        "Nikki Haley": { name: "Nikki Haley", sort_name: "Haley Nikki", party: republican_party},
        "Asa Hutchinson": { name: "Asa Hutchinson", sort_name: "Hutchinson Asa", party: republican_party},
        "Vivek Ramaswamy": { name: "Vivek Ramaswamy", sort_name: "Ramaswamy Vivek", party: republican_party},
        "Corey Stapleton": { name: "Corey Stapleton", sort_name: "Stapleton Corey", party: republican_party},
        "Larry Elder": { name: "Larry Elder", sort_name: "Elder Larry", party: republican_party},
        "Tim Scott": { name: "Tim Scott", sort_name: "Scott Tim", party: republican_party},
        "Ron DeSantis": { name: "Ron DeSantis", sort_name: "DeSantis Ron", party: republican_party},
        "Mike Pence": { name: "Mike Pence", sort_name: "Pence Mike", party: republican_party},
        "Kamala Harris": {name: "Kamala Harris", sort_name: "Harris Kamala", party: democratic_party}
    }
};

export function getPartyForCandidate(campaign, candidate){
    if (trax_campaign_candidates[campaign] && trax_campaign_candidates[campaign][candidate]){
        return trax_campaign_candidates[campaign][candidate].party;
    } else {
        return undefined;
    }
}

function getCampaignOptions(){
    var options = [];
    for (const c in trax_campaign_candidates){
        options.push({value: c, label: c});
    }
    options.reverse();
    return options;
}

export const trax_campaign_options = getCampaignOptions();

function getCandidateOptionsByCampaign(){
    let dict = {};
    for (const cmpgn in trax_campaign_candidates){
        let options = [];
        let names = new Set();
        for (const key in trax_campaign_candidates[cmpgn]){
            const c = trax_campaign_candidates[cmpgn][key];
            if (names.has(key+c.party.name))
                continue;
            options.push({value: key, label: c['name'] + " ("+c['party']['name']+")", sort_name: c['sort_name']});
            names.add(key+c.party.name);
        }
        dict[cmpgn] = options.sort((a, b) => (a.sort_name > b.sort_name) ? 1 : -1);
    }
    return dict;
}

export const trax_candidate_options_by_campaign = getCandidateOptionsByCampaign();